import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import {balancedBins} from "../data/balance-histograms.js"

/* Component */
export const DoubleHistogramBalancedSdm = () => {
    const d3Container = useRef(null);

    useEffect(
        () => {
            // set the dimensions and margins o f the graph
            var margin = { top: 10, right: 30, bottom: 30, left: 40 },
            width = 300 - margin.left - margin.right,
            height = 200 - margin.top - margin.bottom;

            // append the svg object to the body of the page
            var svg = d3.select(d3Container.current)
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")");


            // X axis: scale and draw:
            var x = d3.scaleLinear()
                .domain([0, 1])     // can use this instead of 1000 to have the max of data: d3.max(data, function(d) { return +d.price })
                .range([0, width]);
            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x));
            // And apply twice this function to data to get the bins.
            var {bins1,bins2} = balancedBins;
        
            // Y axis: scale and draw:
            var y = d3.scaleLinear()
                .range([height, 0]);
            y.domain([0, d3.max(bins1, function (d) { return d.size; })]);   // d3.hist has to be called before the Y axis obviously   // d3.hist has to be called before the Y axis obviously
            svg.append("g")
                .call(d3.axisLeft(y));

            // append the bars for series 1
            svg.selectAll("rect")
                .data(bins1)
                .enter()
                .append("rect")
                .attr("x", 1)
                .attr("transform", function (d) { return "translate(" + x(d.x0) + "," + y(d.size) + ")"; })
                .attr("width", function (d) { return x(d.x1) - x(d.x0) - 1; })
                .attr("height", function (d) { return height - y(d.size); })
                .style("fill", "#caa6ed")
                .style("opacity", 0.6)

            // append the bars for series 2
            svg.selectAll("rect2")
                .data(bins2)
                .enter()
                .append("rect")
                .attr("x", 1)
                .attr("transform", function (d) { return "translate(" + x(d.x0) + "," + y(d.size) + ")"; })
                .attr("width", function (d) { return x(d.x1) - x(d.x0) - 1; })
                .attr("height", function (d) { return height - y(d.size); })
                .style("fill", "#7A28CB")
                .style("opacity", 0.6)
            // append the bars for series 2
            svg.append("line")
                .attr("x1", function (d) { return x(0.45); })
                .attr("x2", function (d) { return x(0.45); })
                .attr("y1", function (d) { return y(0); })
                .attr("y2", function (d) { return y(20); })
                .style("stroke-dasharray", ("20, 5"))
                .attr("stroke", "#caa6ed")
                .attr("stroke-width", "5px")
            
                // append the bars for series 2
            svg.append("line")
            .attr("x1", function (d) { return x(0.55); })
            .attr("x2", function (d) { return x(0.55); })
            .attr("y1", function (d) { return y(0); })
            .attr("y2", function (d) { return y(20); })
            .style("stroke-dasharray", ("20, 5"))
            .attr("stroke", "#7A28CB")
            .attr("stroke-width", "5px")

            svg.append("line")
            .attr("x1", function (d) { return x(0.45); })
            .attr("x2", function (d) { return x(0.55); })
            .attr("y1", function (d) { return y(20) + 2.5; })
            .attr("y2", function (d) { return y(20) + 2.5; })
            .attr("stroke", "#7A6D66")
            .attr("stroke-width", "5px")

            svg.append("line")
            .attr("x1", function (d) { return x(0.55); })
            .attr("x2", function (d) { return x(0.55); })
            .attr("y1", function (d) { return y(0); })
            .attr("y2", function (d) { return y(20); })
            .style("stroke-dasharray", ("20, 5"))
            .attr("stroke", "#7A28CB")
            .attr("stroke-width", "5px")

            svg.append("line")
            .attr("x1", function (d) { return x(0.55); })
            .attr("x2", function (d) { return x(0.55); })
            .attr("y1", function (d) { return y(19)+5; })
            .attr("y2", function (d) { return y(21); })
            .attr("stroke", "#7A6D66")
            .attr("stroke-width", "5px")

            svg.append("line")
            .attr("x1", function (d) { return x(0.45); })
            .attr("x2", function (d) { return x(0.45); })
            .attr("y1", function (d) { return y(19)+5; })
            .attr("y2", function (d) { return y(21); })
            .attr("stroke", "#7A6D66")
            .attr("stroke-width", "5px")
            

        },
        [])

    return (
        <div style={{
            margin: "0 auto"
          }}>
            <div
                className="my_dataviz"
                ref={d3Container}
            />
        </div>
    );
}
