import { PropensityScores } from "../../../../content/blog/propensity-score-matching/components/propensity-scores.js";
import { MatchingAlgorithmComparison } from "../../../../content/blog/propensity-score-matching/components/matching-algorithm-comparison.js";
import { LogisticRegression } from "../../../../content/blog/propensity-score-matching/components/logistic-regression.js";
import { MatchingLollipopChart } from "../../../../content/blog/propensity-score-matching/components/matching-lollipop-chart.js";
import * as React from 'react';
export default {
  PropensityScores,
  MatchingAlgorithmComparison,
  LogisticRegression,
  MatchingLollipopChart,
  React
};