import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
var NUM_SAMPLES = 8;
/* Component */
export const PropensityScores = () => {
  const d3Container = useRef(null);


  useEffect(
    () => {
      // set the dimensions and margins of the graph
      var margin = { top: 20, right: 30, bottom: 50, left: 50 },
        width = 375 - margin.left - margin.right,
        height = 420 - margin.top - margin.bottom;
      // Parse the Data
      var data = [...Array(NUM_SAMPLES).keys()];

      function sigmoid(t) {
        t = t * 5 - 2.5
        return 1 / (1 + Math.pow(Math.E, -t));
      }


      data = data.map((index) => {
        var datum = {}
        datum.group = index
        datum.value2 = (parseFloat(sigmoid(((NUM_SAMPLES * 3) / 2 - index) / (NUM_SAMPLES * 2)))
        ).toString()
        datum.value1 = (parseFloat(datum.value2) + 0.03 * Math.random()).toString()
        return datum
      });

      var unmatchedDataExposed = [...Array(NUM_SAMPLES).keys()].map(index => {
        var d = {}
        d.value = (parseFloat(sigmoid(((NUM_SAMPLES * 1) - index) / (NUM_SAMPLES * 4)))
        ).toString()
        d.group = index + (NUM_SAMPLES * 3);
        return d
      })

      var unmatchedDataUnexposed = [...Array(NUM_SAMPLES).keys()].map(index => {
        var d = {}
        d.value = (parseFloat(sigmoid(((NUM_SAMPLES * 4) - index) / (NUM_SAMPLES * 4)))
        ).toString();
        d.group = index;
        return d
      })

      var x = d3.scaleLinear()
        .domain([0, 1])
        .range([0, width]);
      var y = d3.scaleBand()
        .range([0, height])
        .domain([...Array(NUM_SAMPLES * 4).keys()])
        .padding(1);
      if (d3Container.current) {
        // append the svg object to the body of the page
        var svg = d3.select(d3Container.current)
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");
        // X axis
        svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x))

        // text label for the x axis
        svg.append("text")
          .attr("transform",
            "translate(" + (width / 2) + " ," +
            (height + margin.top + 20) + ")")
          .style("text-anchor", "middle")
          .text("Probability Of Ad Exposure");

        // Y axis

        svg.append("g")
          .call(d3.axisLeft(y))

        // text label for the y axis
        svg.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 0 - margin.left)
          .attr("x", 0 - (height / 2))
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text("Sample ID");
        var legendX = 30
        var legendY = 40
        //Legend
        svg.append("rect")
          .attr("x", legendX - 10)
          .attr("y", legendY - 15)
          .attr("width", 140)
          .attr("height", 45)
          .attr("fill", "white")
          .attr("stroke", "black")
          .attr("stroke-width", "1px")

        svg.append("circle").attr("cx", legendX).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
        svg.append("circle").attr("cx", legendX).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
        svg.append("text").attr("x", legendX + 10).attr("y", legendY).text("Exposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")
        svg.append("text").attr("x", legendX + 10).attr("y", legendY + 15).text("Unexposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")

        // Circles of variable 1
        svg.selectAll("mycircle")
          .data(data)
          .enter()
          .append("circle")
          .attr("id", "mycircle1")
          .attr("cx", function (d) { return x(d.value1); })
          .attr("cy", function (d) { return y(d.group * 2 + NUM_SAMPLES); })
          .attr("r", "4")
          .style("fill", "#7A28CB")

        // Circles of variable 2
        svg.selectAll("mycircle")
          .data(data)
          .enter()
          .append("circle")
          .attr("id", "mycircle2")
          .attr("cx", function (d) { return x(d.value2); })
          .attr("cy", function (d) { return y(d.group * 2 + 1 + NUM_SAMPLES); })
          .attr("r", "4")
          .style("fill", "#caa6ed")

        // Circles of variable 1 unmatched
        svg.selectAll("mycircle")
          .data(unmatchedDataExposed)
          .enter()
          .append("circle")
          .attr("id", "mycircle1")
          .attr("cx", function (d) { return x(d.value); })
          .attr("cy", function (d) { return y(d.group); })
          .attr("r", "4")
          .style("fill", "#7A28CB")

        // Circles of variable 2 unmatched
        svg.selectAll("mycircle")
          .data(unmatchedDataUnexposed)
          .enter()
          .append("circle")
          .attr("id", "mycircle2")
          .attr("cx", function (d) { return x(d.value); })
          .attr("cy", function (d) { return y(d.group); })
          .attr("r", "4")
          .style("fill", "#caa6ed")

      }
    },
    [])

  return (
    <div
    style={{
      display: "grid",
      
    }}>
      <div
        style={{
          margin: "0 auto",
        }}
        className="my_dataviz"
        ref={d3Container}
      />
    </div>
  );
}
