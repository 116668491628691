import React, { useRef, useEffect, useState } from 'react'
import * as d3 from 'd3'
var NUM_SAMPLES = 50;
var MAX_AGE = 80;
var horizBarriers = [...Array(9).keys()].map(i => 20000 * (i + 1));
var vertBarriers = [...Array(7).keys()].map(i => 10 * (i + 1));
var margin = { top: 20, right: 30, bottom: 50, left: 50 },
        width = 375 - margin.left - margin.right,
        height = 420 - margin.top - margin.bottom;
var x = d3.scaleLinear()
  .domain([0, MAX_AGE])
  .range([0, width]);
var y = d3.scaleLinear()
  .range([0, height])
  .domain([150000, 0]);
  var legendX = 30
  var legendY = 40

export const CurseOfDimensionality = () => {
  const d3Container = useRef(null);
  const [matched, setMatched] = useState(false)

  useEffect(
    () => {
      
      const producePropensityScoreGraph = (svg) => {
        
     
        // Parse the Data
        var data = [...Array(NUM_SAMPLES).keys()];


        var dataExposed = data.map((index) => {
          var d = {}
          d.x = Math.random() * (MAX_AGE / 2) + MAX_AGE / 2 - Math.random() * (MAX_AGE / 2.3);
          d.y = Math.random() * 150000
          return d
        })

        var dataUnexposed = data.map((index) => {
          var d = {}
          d.x = Math.random() * (MAX_AGE / 2) + Math.random() * (MAX_AGE / 2.3);
          d.y = Math.random() * 150000
          return d
        })




        // append the svg object to the body of the page

        // X axis
        svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x))

        // text label for the x axis
        svg.append("text")
          .attr("transform",
            "translate(" + (width / 2) + " ," +
            (height + margin.top + 20) + ")")
          .style("text-anchor", "middle")
          .text("Age")
          .style("font-weight","bold")
         .style("fill","#7A28CB");;

        // Y axis
        svg.append("g")
          .call(d3.axisLeft(y).tickFormat(d3.format(".2s")))
        // text label for the y axis
        svg.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 0 - margin.left)
          .attr("x", 0 - (height / 2))
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text("Median Household Income Of Zip Code")
          .style("font-weight","bold")
         .style("fill","#7A28CB");

        // Circles of variable 1 unmatched
        svg.selectAll("mycircle")
          .data(dataExposed)
          .enter()
          .append("circle")
          .attr("id", "mycircle1")
          .attr("cx", function (d) { return x(d.x); })
          .attr("cy", function (d) { return y(d.y); })
          .attr("r", "4")
          .style("fill", "#7A28CB")


        // Circles of variable 2 unmatched
        svg.selectAll("mycircle")
          .data(dataUnexposed)
          .enter()
          .append("circle")
          .attr("id", "mycircle2")
          .attr("cx", function (d) { return x(d.x); })
          .attr("cy", function (d) { return y(d.y); })
          .attr("r", "4")
          .style("fill", "#caa6ed")

        
        // Lines
        svg.selectAll("myline")
          .data(horizBarriers)
          .enter()
          .append("line")
          .attr("id", "myline")
          .attr("x1", () => x(0))
          .attr("x2", () => x(80))
          .attr("y1", (d) => y(d))
          .attr("y2", (d) => y(d))
          .attr("stroke", "black")
          .attr("stroke-width", "1px")
          
          svg.selectAll("#vertLines")
          .data(vertBarriers)
          .enter()
          .append("line")
          .attr("id", "vertLines")
          .attr("x1", (d) => x(d))
          .attr("x2", (d) => x(d))
          .attr("y1", () => y(0))
          .attr("y2", () => y(0))
          .attr("stroke", "black")
          .attr("stroke-width", "1px")
          
          //Legend
          svg.append("rect")
            .attr("x", legendX - 10)
            .attr("y", legendY - 15)
            .attr("width", 140)
            .attr("height", 45)
            .attr("fill", "white")
            .attr("stroke", "black")
            .attr("stroke-width", "1px")

        svg.append("circle").attr("cx", legendX).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
        svg.append("circle").attr("cx", legendX).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
        svg.append("text").attr("x", legendX + 10).attr("y", legendY).text("Exposed To Ad").style("font-size", "10px").attr("alignment-baseline", "middle")
        svg.append("text").attr("x", legendX + 10).attr("y", legendY + 15).text("Unexposed To Ad").style("font-size", "10px").attr("alignment-baseline", "middle")




      }

      if (d3Container.current && !matched) {
        var svg = d3.select(d3Container.current)
          .append("svg")
          .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
          .append("g")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");
        producePropensityScoreGraph(svg);

      }

    },
    [matched])

  const handleMatch = () => {
    // Circles of variable 2
    // append the svg object to the body of the page
    var svg = d3.select(d3Container.current)

    

    if (!matched) {
      console.log("matched")

      svg.selectAll("#vertLines")
      .data(vertBarriers)
      .transition()
      .duration(3000)
      .ease(d3.easeLinear)
      .attr("y2", function (d) { return y(150000); })


       //Legend
       svg.append("rect")
       .attr("x", legendX - 10)
       .attr("y", legendY - 15)
       .attr("width", 140)
       .attr("height", 45)
       .attr("fill", "white")
       .attr("stroke", "black")
       .attr("stroke-width", "1px")
       
   svg.append("circle").attr("cx", legendX).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
   svg.append("circle").attr("cx", legendX).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
  


      setMatched(true)
    } else if (matched) {
      svg.select("svg").remove();
      setMatched(false)
    }
  }
  return (
    <div
      style={{
        display: "grid",
      }}>
      <div
        style={{
          margin: "0 auto",
          width: "100%"
        }}
        ref={d3Container}
      />
      <button
        className={'ripple-button'}
        style={{
          margin: "0 auto"
        }}
        onClick={handleMatch}>
        {!matched ?
          "Sub-Classify"
          :
          "Reset"}
      </button>
    </div>
  );
}
