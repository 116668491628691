import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import { smokingData } from "../data/smoking.js"

/* Component */
export const HeterogeneousEffects = () => {
    const d3Container = useRef(null);

    useEffect(
        () => {
            var margin = { top: 10, right: 30, bottom: 40, left: 40 },
                width = 460 - margin.left - margin.right,
                height = 400 - margin.top - margin.bottom;

            // append the svg object to the body of the page
            var svg = d3.select(d3Container.current)
                .append("svg")
                .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
                .append("g")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")");
            // Compute quartiles, median, inter quantile range min and max --> these info are then used to draw the box.
            var sumstat = d3.nest() // nest function allows to group the calculation per level of a factor
                .key(function (d) { return d.Species; })
                .rollup(function (d) {
                    var q1 = d3.quantile(d.map(function (g) { return g.Sepal_Length; }).sort(d3.ascending), .25)
                    var median = d3.quantile(d.map(function (g) { return g.Sepal_Length; }).sort(d3.ascending), .5)
                    var q3 = d3.quantile(d.map(function (g) { return g.Sepal_Length; }).sort(d3.ascending), .75)
                    var interQuantileRange = q3 - q1
                    var min = q1 - 1.5 * interQuantileRange
                    var max = q3 + 1.5 * interQuantileRange
                    return ({ q1: q1, median: median, q3: q3, interQuantileRange: interQuantileRange, min: min, max: max })
                })
                .entries(smokingData)

            // Show the X scale
            var x = d3.scaleBand()
                .range([0, width])
                .domain(["<10", "10-19", ">20"])
                .paddingInner(1)
                .paddingOuter(.5)
            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x))

            // Show the Y scale
            var y = d3.scaleLinear()
                .domain([3, 9])
                .range([height, 0])
            svg.append("g").call(d3.axisLeft(y))

            // Show the main vertical line
            svg
                .selectAll("vertLines")
                .data(sumstat)
                .enter()
                .append("line")
                .attr("x1", function (d) { return (x(d.key)) })
                .attr("x2", function (d) { return (x(d.key)) })
                .attr("y1", function (d) { return (y(d.value.min)) })
                .attr("y2", function (d) { return (y(d.value.max)) })
                .attr("stroke", "black")
                .style("width", 40)

            // rectangle for the main box
//             --primary-color: #A93F55;
//   --title-brown: #52414C;
            var boxWidth = 100
            svg
                .selectAll("boxes")
                .data(sumstat)
                .enter()
                .append("rect")
                .attr("x", function (d) { return (x(d.key) - boxWidth / 2) })
                .attr("y", function (d) { return (y(d.value.q3)) })
                .attr("height", function (d) { return (y(d.value.q1) - y(d.value.q3)) })
                .attr("width", boxWidth)
                .attr("stroke", "#52414C")
                .style("fill", "#f6ebee")

            // Show the median
            svg
                .selectAll("medianLines")
                .data(sumstat)
                .enter()
                .append("line")
                .attr("x1", function (d) { return (x(d.key) - boxWidth / 2) })
                .attr("x2", function (d) { return (x(d.key) + boxWidth / 2) })
                .attr("y1", function (d) { return (y(d.value.median)) })
                .attr("y2", function (d) { return (y(d.value.median)) })
                .attr("stroke", "#52414C")
                .style("width", 80)
                svg.append("text")
                .attr("transform",
                  "translate(" + (width / 2) + " ," +
                  (height + margin.top + 20) + ")")
                .style("text-anchor", "middle")
                .text("Number of Cigarettes Smoked");
              // text label for the y axis
              svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - margin.left)
                .attr("x", 0 - (height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("Mortality Rate Increase");
            // Add individual points with jitter
            var jitterWidth = 50
            svg
                .selectAll("indPoints")
                .data(smokingData)
                .enter()
                .append("circle")
                .attr("cx", function (d) { return (x(d.Species) - jitterWidth / 2 + Math.random() * jitterWidth) })
                .attr("cy", function (d) { return (y(d.Sepal_Length)) })
                .attr("r", 4)
                .style("fill", "#EF3E36")
                .attr("stroke", "#52414C")
        },
        [])

    return (
        <div
            style={{
                display: "grid",
            }}>
            <div
                style={{
                    margin: "0 auto",
                    width: "100%"
                }}
                ref={d3Container}
            />
        </div>
    );
}
