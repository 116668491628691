import { HeterogeneousEffects } from "../../../../content/blog/heterogeneous-treatment-effects/components/heterogeneous-effects.js";
import { CurseOfDimensionality } from "../../../../content/blog/heterogeneous-treatment-effects/components/curse-of-dimensionality.js";
import { Matching } from "../../../../content/blog/heterogeneous-treatment-effects/components/matching.js";
import { SubClassification } from "../../../../content/blog/heterogeneous-treatment-effects/components/sub-classification.js";
import * as React from 'react';
export default {
  HeterogeneousEffects,
  CurseOfDimensionality,
  Matching,
  SubClassification,
  React
};