function roundedRect(x, y, w, h, r, tl, tr, bl, br) {
    let retval;
    retval = `M${x + r},${y}`;
    retval += `h${w - (2 * r)}`;
    if (tr) {
      retval += `a${r},${r} 0 0 1 ${r},${r}`;
    } else {
      retval += `h${r}`; retval += `v${r}`;
    }
    retval += `v${h - (2 * r)}`;
    if (br) {
      retval += `a${r},${r} 0 0 1 ${-r},${r}`;
    } else {
      retval += `v${r}`; retval += `h${-r}`;
    }
    retval += `h${(2 * r) - w}`;
    if (bl) {
      retval += `a${r},${r} 0 0 1 ${-r},${-r}`;
    } else {
      retval += `h${-r}`; retval += `v${-r}`;
    }
    retval += `v${((2 * r) - h)}`;
    if (tl) {
      retval += `a${r},${r} 0 0 1 ${r},${-r}`;
    } else {
      retval += `v${-r}`; retval += `h${r}`;
    }
    retval += 'z';
    return retval;
  }
  

export function rightRoundedRect(x, y, width, height, radius) {
    return roundedRect(x,y,width, height, radius, radius,0,radius,0)
  }

export function leftRoundedRect(x, y, width, height, radius) {
    return roundedRect(x,y,width, height, radius, 0,radius,0,radius)
  }

  export function roundedRectWithPadding(x, y, width, height, radius, padding) {
    return roundedRect(x+ padding,y + padding,width - 2*padding, height - 2*padding, radius,radius,radius,radius,radius)
  }