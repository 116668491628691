import React, { useRef, useEffect, useState } from 'react'
import * as d3 from 'd3'
const ANIMATION_DURATION = 2500;
const NUM_SAMPLES = 8;
const TOTAL_PLOT_HEIGHT = 520;
const TOTAL_PLOT_WIDTH = 500;

/* Component */
// set the dimensions and margins of the graph
const margin = { top: 20, right: 30, bottom: 50, left: 50 };
const chartWidth = TOTAL_PLOT_WIDTH / 2 - margin.left - margin.right;
const width = TOTAL_PLOT_WIDTH / 2;
const height = TOTAL_PLOT_HEIGHT - margin.left - margin.right;
var legendX = 30
var legendY = 40
var legendXMatched = width + margin.right + 20
// Parse the Data
function sigmoid(t) {
  t = t * 5 - 2.5
  return 1 / (1 + Math.pow(Math.E, -t));
}

const data = [...Array(NUM_SAMPLES).keys()].map((index) => {
  var datum = {}
  datum.group = index
  datum.value2 = (parseFloat(sigmoid(((NUM_SAMPLES * 3) / 2 - index) / (NUM_SAMPLES * 2)))
  ).toString()
  datum.value1 = (parseFloat(datum.value2) + 0.06 * Math.random()).toString()
  return datum
});

const unmatchedDataExposed = [...Array(NUM_SAMPLES).keys()].map(index => {
  var d = {}
  d.value = (parseFloat(sigmoid(((NUM_SAMPLES * 1) - index) / (NUM_SAMPLES * 4)))
  ).toString()
  d.group = index + (NUM_SAMPLES * 3);
  return d
})

const unmatchedDataUnexposed = [...Array(NUM_SAMPLES).keys()].map(index => {
  var d = {}
  d.value = (parseFloat(sigmoid(((NUM_SAMPLES * 4) - index +1) / (NUM_SAMPLES * 4)))
  ).toString();
  d.group = index;
  return d
})



const x = d3.scaleLinear()
  .domain([0, 1])
  .range([0, width - margin.right / 2]);

const xMatched = d3.scaleLinear()
  .domain([0, 1])
  .range([width + margin.right / 2, TOTAL_PLOT_WIDTH]);

const y = d3.scaleBand()
  .range([0, height])
  .domain([...Array(NUM_SAMPLES * 4).keys()])
  .padding(1);

const yMatched = d3.scaleBand()
  .range([0, height])
  .domain([...Array(NUM_SAMPLES).keys()])
  .padding(1);

export const MatchingLollipopChart = () => {
  const d3Container = useRef(null);
  const [matched, setMatched] = useState(false)


  useEffect(
    () => {

      const producePropensityScoreGraph = (svg) => {

        //title 
        svg.append("text")
          .attr("x", (width) / 2)
          .attr("y", 0)
          .attr("text-anchor", "middle")
          .style("font-size", "12px")
          .text("Sorted Propensity Scores of Individuals");
        // X axis
        svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x))

        // text label for the x axis
        svg.append("text")
          .attr("transform",
            "translate(" + (width / 2) + " ," +
            (height + margin.top + 20) + ")")
          .style("text-anchor", "middle")
          .text("Probability Of Ad Exposure");

        // Y axis

        svg.append("g")
          .call(d3.axisLeft(y))

        // text label for the y axis
        svg.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 0 - margin.left)
          .attr("x", 0 - (height) / 2)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text("Sample ID");
        // Lines
        svg.selectAll("myline")
          .data(data)
          .enter()
          .append("line")
          .attr("id", "myline")
          .attr("x1", function (d) { return x(d.value1); })
          .attr("x2", function (d) { return x(d.value1); })
          .attr("y1", function (d) { return y(d.group * 2 + NUM_SAMPLES); })
          .attr("y2", function (d) { return y(d.group * 2 + NUM_SAMPLES); })
          .attr("stroke", "grey")
          .attr("stroke-width", "1px")

        //Legend
        svg.append("rect")
          .attr("x", legendX - 10)
          .attr("y", legendY - 15)
          .attr("width", 140)
          .attr("height", 45)
          .attr("fill", "white")
          .attr("stroke", "black")
          .attr("stroke-width", "1px")

        svg.append("circle").attr("cx", legendX).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
        svg.append("circle").attr("cx", legendX).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
        svg.append("text").attr("x", legendX + 10).attr("y", legendY).text("Exposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")
        svg.append("text").attr("x", legendX + 10).attr("y", legendY + 15).text("Unexposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")

        // Circles of variable 1
        svg.selectAll("mycircle")
          .data(data)
          .enter()
          .append("circle")
          .attr("id", "mycircle1")
          .attr("cx", function (d) { return x(d.value1); })
          .attr("cy", function (d) { return y(d.group * 2 + NUM_SAMPLES); })
          .attr("r", "4")
          .style("fill", "#7A28CB")

        // Circles of variable 2
        svg.selectAll("mycircle")
          .data(data)
          .enter()
          .append("circle")
          .attr("id", "mycircle2")
          .attr("cx", function (d) { return x(d.value2); })
          .attr("cy", function (d) { return y(d.group * 2 + NUM_SAMPLES + 1); })
          .attr("r", "4")
          .style("fill", "#caa6ed")

        // Circles of variable 1 unmatched
        svg.selectAll("mycircle")
          .data(unmatchedDataExposed)
          .enter()
          .append("circle")
          .attr("id", "mycircle1")
          .attr("cx", function (d) { return x(d.value); })
          .attr("cy", function (d) { return y(d.group); })
          .attr("r", "4")
          .style("fill", "#7A28CB")

        // Circles of variable 2 unmatched
        svg.selectAll("mycircle")
          .data(unmatchedDataUnexposed)
          .enter()
          .append("circle")
          .attr("id", "mycircle2")
          .attr("cx", function (d) { return x(d.value); })
          .attr("cy", function (d) { return y(d.group); })
          .attr("r", "4")
          .style("fill", "#caa6ed")

      }

      const produceMatchedGraph = (svg) => {
        
        //title

        svg.append("text")
          .attr("x", (width + ((width + margin.left) / 2)))
          .attr("y", 0)
          .attr("text-anchor", "middle")
          .style("font-size", "12px")
          .text("Propensity Scores Of Matched Samples");

        // X axis
        svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(xMatched))

        // text label for the x axis
        svg.append("text")
          .attr("transform",
            "translate(" + (width + (chartWidth / 2) + margin.left) + " ," +
            (height + margin.top + 20) + ")")
          .style("text-anchor", "middle")
          .text("Probability Of Ad Exposure");

        // Y axis
        svg.append("g")
          .attr("transform",
            "translate(" + (width + margin.right / 2) + " ,0)")
          .call(d3.axisLeft(yMatched))
        //Legend
        svg.append("rect")
          .attr("x", legendXMatched - 10)
          .attr("y", legendY - 15)
          .attr("width", 140)
          .attr("height", 45)
          .attr("fill", "white")
          .attr("stroke", "black")
          .attr("stroke-width", "1px")

        svg.append("circle").attr("cx", legendXMatched).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
        svg.append("circle").attr("cx", legendXMatched).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
        svg.append("text").attr("x", legendXMatched + 10).attr("y", legendY).text("Exposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")
        svg.append("text").attr("x", legendXMatched + 10).attr("y", legendY + 15).text("Unexposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")

        // text label for the y axis
        svg.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", width - margin.left/2)
          .attr("x", 0 - (height) / 2)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text("Matched Sample ID");

      }
      if (d3Container.current && !matched) {
        var svg = d3.select(d3Container.current)
          .append("svg")
          .attr("preserveAspectRatio", "xMinYMin meet")
          .attr("viewBox", `0 0 ${TOTAL_PLOT_WIDTH + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
          .append("g")
          .attr("width", TOTAL_PLOT_WIDTH + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");
        producePropensityScoreGraph(svg);
        produceMatchedGraph(svg);
        
      }

    },
    [matched])

  const handleMatch = () => {
    // Circles of variable 2
    // append the svg object to the body of the page
    var svg = d3.select(d3Container.current)
    if (!matched) {

      svg.selectAll("#myline")
        .data(data)
        .transition()
        .duration(ANIMATION_DURATION)
        .ease(d3.easeLinear)
        .attr("y1", function (d) { return yMatched(d.group); })
        .attr("y2", function (d) { return yMatched(d.group); })
        .attr("x1", function (d) { return xMatched(d.value1); })
        .attr("x2", function (d) { return xMatched(d.value1); })
        .transition()
        .duration(ANIMATION_DURATION)
        .ease(d3.easeLinear)
        .attr("x2", function (d) { return xMatched(d.value2); })

      svg.selectAll("#mycircle1")
        .data(data)
        .transition()
        .duration(ANIMATION_DURATION)
        .ease(d3.easeLinear)
        .attr("cx", (d) => xMatched(d.value1))
        .attr("cy", (d) => yMatched(d.group));

      svg.selectAll("#mycircle2")
        .data(data)
        .transition()
        .duration(ANIMATION_DURATION)
        .ease(d3.easeLinear)
        .attr("cx", (d) => xMatched(d.value2))
        .attr("cy", (d) => yMatched(d.group));
      setMatched(true)
    } else if (matched) {
      svg.select("svg").remove();
      setMatched(false)
    }
  }
  return (
    <div
    style={{
      display: "grid",
    }}>
      <div
        style={{
          margin: "0 auto",
          width:"100%"
        }}
        ref={d3Container}
      />
      <button 
      className={'ripple-button'}
      style={{
        margin: "0 auto"
      }}
      onClick={handleMatch}>
        {!matched ?
          "Match Observations"
          :
          "Reset"}
      </button>
    </div>
  );
}
