import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import data from '../data/decision-tree.json'
import '../styles/decision-tree.css'
import { rightRoundedRect, leftRoundedRect, roundedRectWithPadding } from '../utils/utils.js'

export const DecisionTree = () => {
  const d3Container = useRef(null);
  // Toggle children.

  useEffect(
    () => {

      var w = 800,
        h = 800,
        i = 0,
        max_node_width = 80,
        layer_height = 200, //y difference between nodes (adjustable)
        rect_height = 80, //global height of every node (adjustable to preference)
        rect_width = 110,
        treeRoot,
        n_samples,
        line = d3.line()
          .curve(d3.curveBasis);

      //tree svg
      var vis = d3.select(d3Container.current).append("svg:svg")
      .attr("viewBox", `0 0 ${w} ${h}`)
        
        .append("svg:g")
        
        
        .attr("transform", "translate(" + -10 + "," + 20 + ")")


      var tree = d3.tree()
        .size([h, w]);

      function load_dataset(json) {
        treeRoot = d3.hierarchy(json);
        tree(treeRoot)
        n_samples = treeRoot.data.samples; //total number of samples in tree

        //recursively toggles all nodes in tree 
        function toggleAll(d) {
          if (d && d.children) {
            d.children.forEach(toggleAll);
            toggle(d);
          }
        }

        function scaleAll(d) {
          scale(d);
          if (d && d.children) {
            d.children.forEach(scaleAll);
          }
        }
        scaleAll(treeRoot);
        // Initialize the display to show a few nodes.
        // turns off all not in the first layer
        treeRoot.children.forEach(toggleAll);

        //draws
        update(treeRoot);
      }



      // Toggle children.
      function toggle(d) {
        if (d.children) {
          d._children = d.children;
          d.children = null;
        } else {
          d.children = d._children;
          d._children = null;
        }
      }

      //scales nodes and connecting width
      //useful for handling new data with more/less entries per node
      //called only once
      function scale(d) {
        //Scale samples and value
        d.data.samples /= n_samples / max_node_width;
        d.data.value[0] /= n_samples / max_node_width;
        d.data.value[1] /= n_samples / max_node_width;
      }

      function update(source) {
        var duration = d3.event && d3.event.altKey ? 5000 : 500;

        // nodes
        // Compute the new tree layout.
        var nodes = treeRoot.descendants().reverse();

        // Normalize for fixed-depth.
        nodes.forEach(function (d) {
          d.y = d.depth * layer_height;
          if ((d.data.te + d.data.ci * 2) > 0 && (d.data.te - d.data.ci * 2) < 0) {
            d.data.teFill = "#F18F01"
          } else if (d.data.te > 0) {
            d.data.teFill = "#98CE00"
          } else {
            d.data.teFill = "#D44D5C"
          }
        });

        // Update the nodes…
        var node = vis.selectAll("g.node")
          .data(nodes, function (d) { return d.id || (d.id = ++i); });

        // Enter any new nodes at the parent's previous position.
        var nodeEnter = node
          .enter().append("svg:g")
          .attr("class", "node")
          .attr("transform", function (d) { return "translate(" + source.x + "," + source.y + ")"; })
          .on("click", function (d) {
            toggle(d); update(d);
          });

        /* treated box*/
        nodeEnter.append("svg:path")
          .attr("d", function (d) {
            return rightRoundedRect(-rect_width / 2 + d.data.value[0] / 2,
              0,
              rect_width / 2,
              rect_height,
              5)
          })
          .style("fill", function (d) { return "#7A28CB"; })
        // description of split
        // designed so that position is relative to node
        // content of text is specified in json 
        nodeEnter.append("svg:text")
          .attr("x", function (d) {
            if (d && d.parent && d.parent.x > d.x) { return -max_node_width * .8; }
            else { return d.data.samples };
          }) //text is outside tree
          .attr("y", -layer_height / 3) //text displays a little above node
          .text(function (d) { return d.data.label })
          .style("stroke", function (d) { return ""; })
          .style("fill", function (d) { return "black"; });



        /* untreated box*/
        nodeEnter.append("svg:path")
          .attr("d", function (d) {
            return leftRoundedRect((d.data.value[0]) ? (d.data.value[0] / 2) : (-d.data.value[1] / 2),
              0,
              rect_width / 2,
              rect_height,
              5)
          })
          .style("fill", function (d) { return "#caa6ed"; })
        // invisible box surrounding both 
        // used for mouseover effects
        nodeEnter.append("svg:path")
          .attr("d", function (d) {
            return roundedRectWithPadding(-rect_width / 2 + d.data.value[0] / 2,
              0,
              rect_width,
              rect_height,
              5,
              6)
          })
          .style("fill", function (d) { return "white"; })
          .text(function (d) { return Math.round((d.data.value[0] + d.data.value[1]) * n_samples / max_node_width); });

        // Creating red green and green path objects
        // Paths will connect upward from child to parent
        // paths are broken into 2 classes (one for each category)
        // this is because the math is a little different depending on path color

        nodeEnter.append("svg:path")
          .attr('class', 'leftlink')
          .style('stroke', "#7A28CB")
          .style('stroke-width', function (d) {
            return d.data.value[0];
          })
          .attr("transform", function (d) { return "scale(" + .1 + "," + .1 + ")"; }) //path starts tiny
          .on('mouseover', function () {
            d3.select(this).attr('stroke-opacity', '.7')
          })
          .on('mouseout', function () {
            d3.select(this).attr('stroke-opacity', '1')
          })
          .append("svg:title")
          .text(function (d) { return Math.round(d.data.value[0] * n_samples / max_node_width); }); //displays tooltip on mouseover

        nodeEnter.append("svg:path")
          .attr('class', 'rightlink')
          .style('stroke', "#caa6ed")
          .style('stroke-width', function (d) {
            return d.data.value[0];
          })
          .attr("transform", function (d) { return "scale(" + .1 + "," + .1 + ")"; }) //path starts tiny
          .on('mouseover', function () {
            d3.select(this).attr('stroke-opacity', '.7')
          })
          .on('mouseout', function () {
            d3.select(this).attr('stroke-opacity', '1')
          })
          .append("svg:title")
          .text(function (d) { return Math.round(d.data.value[1] * n_samples / max_node_width); }); //displays tooltip on mouseover



        nodeEnter.append("svg:text")
          .attr("x", (d) => (d.data.value[0] / 2 - rect_width / 5))
          .attr("y", rect_height / 2 - 10)
          .text(function (d) { return Math.round((d.data.value[0]) * n_samples / max_node_width)  })
          .style("fill", function (d) { return "#7A28CB"; })
          .style("text-anchor", "middle")
          .style("font-size", "14px");

        nodeEnter.append("svg:text")
          .attr("x", (d) => (d.data.value[0] / 2 - rect_width / 5))
          .attr("y", rect_height / 2 + 2)
          .text("Treated")
          .style("fill", function (d) { return "#7A28CB"; })
          .style("text-anchor", "middle")
          .style("font-size", "10px");

        nodeEnter.append("svg:text")
          .attr("x", (d) => (d.data.value[0] / 2 + rect_width / 5))
          .attr("y", rect_height / 2 - 10)
          .text(function (d) { return  Math.round((d.data.value[1]) * n_samples / max_node_width) })
          .style("fill", function (d) { return "#caa6ed"; })
          .style("text-anchor", "middle")
          .style("font-size", "14px");

        nodeEnter.append("svg:text")
          .attr("x", (d) => (d.data.value[0] / 2 + rect_width / 5))
          .attr("y", rect_height / 2 + 2)
          .text("Untreated")
          .style("fill", function (d) { return "#caa6ed"; })
          .style("text-anchor", "middle")
          .style("font-size", "10px");

        nodeEnter.append("svg:text")
          .attr("x", (d) => (d.data.value[0] / 2))
          .attr("y", rect_height / 2 + 20)
          .text((d) => (`${(d.data.te * 100).toFixed(1)}%`))
          .style("fill", (d) => d.data.teFill)
          .style("text-anchor", "middle")
          .style("font-size", "13px")
          .attr("font-weight", "bold");

        // Transition nodes to their new position.
        var nodeUpdate = nodeEnter.transition()
          .duration(duration)
          .attr("transform", function (d) { return "translate(" + d.x + "," + d.y + ")"; });
        // handles tricky math to create path from child node to parent
        // each path is composed of 4 points (0/3 1/3 2/3 3/3) up the path
        //.leftlink = green path, .rightlink = red path
        nodeUpdate.select(".leftlink")
          .style('stroke-width', function (d) {
            return d.data.value[0];
          })
          .attr("transform", function (d) { return "scale(" + 1 + "," + 1 + ")"; })
          .style('stroke', "#7A28CB")
          .attr('d', function (d) {

            var points;
            if (d.parent) {
              //left node of treated branch
              if (d.parent.x > d.x) {
                points = [
                  [0, 0],
                  [0, -layer_height / 3],
                  [d.parent.x - d.x + d.data.value[0] / 2 - d.parent.data.value[0] / 2, -2 * layer_height / 3 + rect_height],
                  [d.parent.x - d.x + d.data.value[0] / 2 - d.parent.data.value[0] / 2, -(layer_height - rect_height)]
                ];
              }
              //right node of untreated branch
              else {
                points = [
                  [0, 0],
                  [0, -layer_height / 3],
                  [d.parent.x - d.x + (d.parent.data.value[0] - d.data.value[0]) / 2, -2 * layer_height / 3 + rect_height],
                  [d.parent.x - d.x + (d.parent.data.value[0] - d.data.value[0]) / 2,
                  -(layer_height - rect_height)]
                ];
              }


            }
            else {
              points = [[0, 0], [0, 0]] //no parent, no path
            }
            return line(points);
          })
          .attr("transform", function (d) { return "scale(" + 1 + "," + 1 + ")"; }) //"grows" path
          .transition().duration(duration);



        // for the red path, the height of the 2 middle points are offset
        // this is done to avoid overlap with the green path
        // offset is 1/6 of layer height currently
        // height of the middle two points can be adjusted to fit preferences

        nodeUpdate.select(".rightlink")
          .style('stroke-width', function (d) {
            return d.data.value[1];
          })
          .style('stroke', "#caa6ed")
          .attr("transform", function (d) { return "scale(" + 1 + "," + 1 + ")"; })
          .attr('d', function (d) {
            var points;
            if (d.parent) {
              //red path left node
              if (d.parent.x > d.x) {
                points = [
                  [d.data.value[0] / 2 + d.data.value[1] / 2, 0],
                  [d.data.value[0] / 2 + d.data.value[1] / 2, -(1 / 3 * layer_height - 1 / 6 * layer_height)],
                  [d.parent.x - d.x + d.parent.data.value[0] / 2 + d.parent.data.value[1] / 2 - (d.parent.data.value[1] - d.data.value[1]) / 2, -2 / 3 * layer_height + rect_height],
                  [d.parent.x - d.x + d.parent.data.value[0] / 2 + d.parent.data.value[1] / 2 - (d.parent.data.value[1] - d.data.value[1]) / 2, -(layer_height - rect_height)]
                ];
              }
              //red path right node
              else {
                points = [
                  [d.data.value[0] / 2 + d.data.value[1] / 2, 0],
                  [d.data.value[0] / 2 + d.data.value[1] / 2, - (1 / 3 * layer_height)],
                  [d.parent.x - d.x + d.parent.data.value[0] / 2 + d.parent.data.value[1] / 2 + (d.parent.data.value[1] - d.data.value[1]) / 2, -(2 / 3 * layer_height + layer_height / 6) + rect_height],
                  [d.parent.x - d.x + d.parent.data.value[0] / 2 + d.parent.data.value[1] / 2 + (d.parent.data.value[1] - d.data.value[1]) / 2, -(layer_height - rect_height)]
                ];
              }
            }
            else {
              points = [[0, 0], [0, 0]] //no parent, no path
            }
            return line(points);
          })
          .transition().duration(duration)


        // Transition exiting nodes to the parent's new position.
        var nodeExit = node.exit().transition()
          .duration(duration)
          .attr("transform", function (d) { return "translate(" + source.x + "," + source.y + ")"; })

        //makes path smaller on exit
        nodeExit.select(".leftlink")
          //.style('stroke-width', 0)
          .duration(duration).attr('transform', function (d) {
            return "scale(" + 0 + "," + 0 + ")";
          })

        //makes path smaller on exit
        nodeExit.select(".rightlink")
          //.style('stroke-width', 0)
          .duration(duration).attr('transform', function (d) {
            return "scale(" + 0 + "," + 0 + ")";
          })
        nodeExit.select("text")
          .style("fill-opacity", 1e-6);
        // Stash the old positions for transition.
        nodes.forEach(function (d) {
          d.x0 = d.x;
          d.y0 = d.y;
        });
      }


      load_dataset(data)

    },
    [])

  return (

    <div
      style={{
        height:600
      }}
      ref={d3Container}
    />
  );
}
