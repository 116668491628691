import { DoubleHistogramUnbalanced } from "../../../../content/blog/propensity-score-matching-threats/components/double-histogram-unbalanced.js";
import { DoubleHistogramBalanced } from "../../../../content/blog/propensity-score-matching-threats/components/double-histogram-balanced.js";
import { MatchingLollipopChart } from "../../../../content/blog/propensity-score-matching-threats/components/matching-lollipop-chart.js";
import { MatchingLollipopChartBalanced } from "../../../../content/blog/propensity-score-matching-threats/components/matching-lollipop-chart-balanced.js";
import { DoubleHistogramBalancedSdm } from "../../../../content/blog/propensity-score-matching-threats/components/double-histogram-balanced-sdm.js";
import { DoubleHistogramUnbalancedSdm } from "../../../../content/blog/propensity-score-matching-threats/components/double-histogram-unbalanced-sdm.js";
import * as React from 'react';
export default {
  DoubleHistogramUnbalanced,
  DoubleHistogramBalanced,
  MatchingLollipopChart,
  MatchingLollipopChartBalanced,
  DoubleHistogramBalancedSdm,
  DoubleHistogramUnbalancedSdm,
  React
};