import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
const NUM_SAMPLES = 8;
/* Component */
export const MatchingAlgorithmComparison = () => {
    const d3Container = useRef(null);

    useEffect(
        () => {
            // set the dimensions and margins of the graph
            var margin = { top: 20, right: 30, bottom: 50, left: 50 },
                width = 280 - margin.left - margin.right,
                height = 400 - margin.top - margin.bottom;
            var legendX = 30
            var legendY = 40
            // Parse the Data
            function sigmoid(t) {
                t = t * 5 - 2.5
                return 1 / (1 + Math.pow(Math.E, -t));
            }

            if (d3Container.current) {
                // append the svg object to the body of the page
                const produceGraph = (title) => {
                    var noise = title === "Greedy Matching" ? 0.1 : 0.05;
                    var data = [...Array(NUM_SAMPLES).keys()].map((index) => {
                        var datum = {}
                        datum.group = index
                        datum.value1 = (parseFloat(sigmoid((NUM_SAMPLES - index) / NUM_SAMPLES) + Math.random() * 0.02 - 0.01)
                        ).toString()
                        datum.value2 = (parseFloat(datum.value1) + Math.random() * noise * 2 - noise).toString()
                        return datum
                    });
                    var x = d3.scaleLinear()
                        .domain([0, 1])
                        .range([0, width]);
                    var y = d3.scaleBand()
                        .range([0, height])
                        .domain(data.map(function (d) { return d.group; }))
                        .padding(1);


                    var svg = d3.select(d3Container.current)
                        .append("svg")
                        .attr("width", width + margin.left + margin.right)
                        .attr("height", height + margin.top + margin.bottom)
                        .append("g")
                        .attr("transform",
                            "translate(" + margin.left + "," + margin.top + ")");
                    //title
                    svg.append("text")
                        .attr("x", ((margin.right + 20 + width) / 2))
                        .attr("y", 0)
                        .attr("text-anchor", "middle")
                        .style("font-size", "16px")
                        .text(title);
                    // X axis
                    svg.append("g")
                        .attr("transform", "translate(0," + height + ")")
                        .call(d3.axisBottom(x))
                    // text label for the x axis
                    svg.append("text")
                        .attr("transform",
                            "translate(" + (width / 2) + " ," +
                            (height + margin.top + 20) + ")")
                        .style("text-anchor", "middle")
                        .text("Probability Of Ad Exposure");

                    // Y axis
                    svg.append("g")
                        .call(d3.axisLeft(y))

                    // text label for the y axis
                    svg.append("text")
                        .attr("transform", "rotate(-90)")
                        .attr("y", 0 - margin.left)
                        .attr("x", 0 - (height / 2))
                        .attr("dy", "1em")
                        .style("text-anchor", "middle")
                        .text("Matched Sample ID");
                    //Legend
                    svg.append("rect")
                        .attr("x", legendX - 10)
                        .attr("y", legendY - 15)
                        .attr("width", 140)
                        .attr("height", 45)
                        .attr("fill", "white")
                        .attr("stroke", "black")
                        .attr("stroke-width", "1px")

                    svg.append("circle").attr("cx", legendX).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
                    svg.append("circle").attr("cx", legendX).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
                    svg.append("text").attr("x", legendX + 10).attr("y", legendY).text("Exposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")
                    svg.append("text").attr("x", legendX + 10).attr("y", legendY + 15).text("Unexposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")
                    // Lines
                    svg.selectAll("myline")
                        .data(data)
                        .enter()
                        .append("line")
                        .attr("id", "myline")
                        .attr("x1", function (d) { return x(d.value1); })
                        .attr("x2", function (d) { return x(d.value2); })
                        .attr("y1", function (d) { return y(d.group); })
                        .attr("y2", function (d) { return y(d.group); })
                        .attr("stroke", "grey")
                        .attr("stroke-width", "1px")


                    // Circles of variable 1
                    svg.selectAll("mycircle")
                        .data(data)
                        .enter()
                        .append("circle")
                        .attr("id", "mycircle1")
                        .attr("cx", function (d) { return x(d.value1); })
                        .attr("cy", function (d) { return y(d.group); })
                        .attr("r", "4")
                        .style("fill", "#7A28CB")

                    // Circles of variable 2
                    svg.selectAll("mycircle")
                        .data(data)
                        .enter()
                        .append("circle")
                        .attr("id", "mycircle2")
                        .attr("cx", function (d) { return x(d.value2); })
                        .attr("cy", function (d) { return y(d.group); })
                        .attr("r", "4")
                        .style("fill", "#caa6ed")

                }
                produceGraph("Greedy Matching");
                produceGraph("Optimal Matching");
            }

        },
        [])


    return (
        <div
            style={{
                display: "grid",
            }}>
            <div
                style={{
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                className="my_dataviz"
                ref={d3Container}
            />
        </div>
    );
}
