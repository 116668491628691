import React, { useRef, useEffect, useState } from 'react'
import * as d3 from 'd3'
import { matchingData } from "../data/estimating-cate.js"
const ANIMATION_DURATION = 2500;
const NUM_SAMPLES = 8;
const TOTAL_PLOT_HEIGHT = 520;
const TOTAL_PLOT_WIDTH = 500;
const CIRCLE_RADIUS = 4;
/* Component */
// set the dimensions and margins of the graph
const margin = { top: 20, right: 30, bottom: 50, left: 50 };
const chartWidth = TOTAL_PLOT_WIDTH / 2 - margin.left - margin.right;
const width = TOTAL_PLOT_WIDTH / 2;
const height = TOTAL_PLOT_HEIGHT - margin.left - margin.right;
var legendX = 30
var legendY = 40
var legendXMatched = width + margin.right + 20

const unmatchedDataExposed = [...Array(NUM_SAMPLES).keys()].map(index => {
  var d = {}
  d.value = 1
  d.group = (index + (NUM_SAMPLES * 2.2)) * 3;
  return d
})

const unmatchedDataUnexposed = [...Array(NUM_SAMPLES).keys()].map(index => {
  var d = {}
  d.value = 0;
  d.group = index * 3 + 20;
  return d
})

const x = d3.scaleLinear()
  .domain([0, 1])
  .range([0, width - margin.right / 2]);

const xMatched = d3.scaleLinear()
  .domain([0, 1])
  .range([width + margin.right / 2, TOTAL_PLOT_WIDTH]);

const y = d3.scaleLinear()
  .range([height, 0])
  .domain([0, 80]);

const yMatched = d3.scaleLinear()
  .range([height, 0])
  .domain([0, 80]);

export const Matching = () => {
  const d3Container = useRef(null);
  const [matched, setMatched] = useState(false)


  useEffect(
    () => {

      const producePropensityScoreGraph = (svg) => {
        //title 
        svg.append("text")
          .attr("x", (width) / 2)
          .attr("y", 0)
          .attr("text-anchor", "middle")
          .style("font-size", "12px")
          .text("Observed Individuals");
        //title
        svg.append("text")
          .attr("x", (width + ((width + margin.left) / 2)))
          .attr("y", 0)
          .attr("text-anchor", "middle")
          .style("font-size", "12px")
          .text("Mactched Samples");

        // X axis
        svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x))

        // text label for the x axis
        svg.append("text")
          .attr("transform",
            "translate(" + (width / 2) + " ," +
            (height + margin.top + 20) + ")")
          .style("text-anchor", "middle")
          .text("Purchases Flight")
          .style("font-weight", "bold")
          .style("fill", "#EF3E36");

        // Y axis
        svg.append("g")
          .call(d3.axisLeft(y))

        // text label for the y axis
        svg.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 0 - margin.left)
          .attr("x", 0 - (height) / 2)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text("Age")
          .style("font-weight", "bold")
          .style("fill", "#7A28CB");
        svg.append("svg:defs").append("svg:marker")
          .attr("id", "triangle")
          .attr("refX", 6)
          .attr("refY", 6)
          .attr("markerWidth", 30)
          .attr("markerHeight", 30)
          .attr("orient", "auto")
          .append("path")
          .attr("d", "M 0 0 12 6 0 12 3 6")
          .style("fill", "#EF3E36");
        // Lines
        svg.selectAll("myline")
          .data(matchingData)
          .enter()
          .append("line")
          .attr("id", "myline")
          .attr("x1", function (d) { return x(d.value2); })
          .attr("x2", function (d) { return x(d.value2); })
          .attr("y1", function (d) { return y(d.group * 2+1); })
          .attr("y2", function (d) { return y(d.group * 2+1); })
          .attr("stroke", "#EF3E36")
          .attr("stroke-width", "0.5px")
          .attr("marker-end", "url(#triangle)");

        //Legend
        svg.append("rect")
          .attr("x", legendX - 10)
          .attr("y", legendY - 15)
          .attr("width", 140)
          .attr("height", 45)
          .attr("fill", "white")
          .attr("stroke", "black")
          .attr("stroke-width", "1px")

        svg.append("circle").attr("cx", legendX).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
        svg.append("circle").attr("cx", legendX).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
        svg.append("text").attr("x", legendX + 10).attr("y", legendY).text("Exposed To Ad").style("font-size", "10px").attr("alignment-baseline", "middle")
        svg.append("text").attr("x", legendX + 10).attr("y", legendY + 15).text("Unexposed To Ad").style("font-size", "10px").attr("alignment-baseline", "middle")

        // Circles of variable 1
        svg.selectAll("mycircle")
          .data(matchingData)
          .enter()
          .append("circle")
          .attr("id", "mycircle1")
          .attr("cx", function (d) { return x(d.value1); })
          .attr("cy", function (d) { return y(d.group * 2); })
          .attr("r", CIRCLE_RADIUS)
          .style("fill", "#7A28CB")

        // Circles of variable 2
        svg.selectAll("mycircle")
          .data(matchingData)
          .enter()
          .append("circle")
          .attr("id", "mycircle2")
          .attr("cx", function (d) { return x(d.value2); })
          .attr("cy", function (d) { return y(d.group * 2 + 1); })
          .attr("r", CIRCLE_RADIUS)
          .style("fill", "#caa6ed")

        // Circles of variable 1 unmatched
        svg.selectAll("mycircle")
          .data(unmatchedDataExposed)
          .enter()
          .append("circle")
          .attr("id", "mycircle1")
          .attr("cx", function (d) { return x(d.value); })
          .attr("cy", function (d) { return y(d.group); })
          .attr("r", CIRCLE_RADIUS)
          .style("fill", "#7A28CB")

        // Circles of variable 2 unmatched
        svg.selectAll("mycircle")
          .data(unmatchedDataUnexposed)
          .enter()
          .append("circle")
          .attr("id", "mycircle2")
          .attr("cx", function (d) { return x(d.value); })
          .attr("cy", function (d) { return y(d.group + 15); })
          .attr("r", CIRCLE_RADIUS)
          .style("fill", "#caa6ed")

      }

      const produceMatchedGraph = (svg) => {



        // X axis
        svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(xMatched))

        // text label for the x axis
        svg.append("text")
          .attr("transform",
            "translate(" + (width + (chartWidth / 2) + margin.left) + " ," +
            (height + margin.top + 20) + ")")
          .style("text-anchor", "middle")
          .text("Purchases Flight")
          .style("font-weight", "bold")
          .style("fill", "#EF3E36");

        // Y axis
        svg.append("g")
          .attr("transform",
            "translate(" + (width + margin.right / 2) + " ,0)")
          .call(d3.axisLeft(yMatched))
        //Legend
        svg.append("rect")
          .attr("x", legendXMatched - 10)
          .attr("y", legendY - 15)
          .attr("width", 140)
          .attr("height", 45)
          .attr("fill", "white")
          .attr("stroke", "black")
          .attr("stroke-width", "1px")

        svg.append("circle").attr("cx", legendXMatched).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
        svg.append("circle").attr("cx", legendXMatched).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
        svg.append("text").attr("x", legendXMatched + 10).attr("y", legendY).text("Exposed To Ad").style("font-size", "10px").attr("alignment-baseline", "middle")
        svg.append("text").attr("x", legendXMatched + 10).attr("y", legendY + 15).text("Unexposed To Ad").style("font-size", "10px").attr("alignment-baseline", "middle")


      }
      if (d3Container.current && !matched) {
        var svg = d3.select(d3Container.current)
          .append("svg")
          .attr("preserveAspectRatio", "xMinYMin meet")
          .attr("viewBox", `0 0 ${TOTAL_PLOT_WIDTH + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
          .append("g")
          .attr("width", TOTAL_PLOT_WIDTH + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");
            produceMatchedGraph(svg);
        producePropensityScoreGraph(svg);
        

      }

    },
    [matched])

  const handleMatch = () => {
    // Circles of variable 2
    // append the svg object to the body of the page
    var svg = d3.select(d3Container.current)
    if (!matched) {

      svg.selectAll("#myline")
        .data(matchingData)
        .transition()
        .duration(ANIMATION_DURATION)
        .ease(d3.easeLinear)
        .attr("y1", function (d) { return yMatched(d.group * 2+ 1); })
        .attr("y2", function (d) { return yMatched(d.group * 2 + 1); })
        .attr("x1", function (d) { return xMatched(d.value2); })
        .attr("x2", function (d) { return xMatched(d.value2); })
        .transition()
        .duration(ANIMATION_DURATION)
        .ease(d3.easeLinear)
        .attr("y2", function (d) { return yMatched(d.group * 2); })
        //only offset error if both sides of arrow are on different sides of the chart
        .attr("x2", function (d) { return (d.value1 === d.value2 ? xMatched(d.value1) : (xMatched(d.value1) - (CIRCLE_RADIUS+2))) })

      svg.selectAll("#mycircle1")
        .data(matchingData)
        .transition()
        .duration(ANIMATION_DURATION)
        .ease(d3.easeLinear)
        .attr("cx", (d) => xMatched(d.value1))
        .attr("cy", (d) => yMatched(d.group * 2));

      svg.selectAll("#mycircle2")
        .data(matchingData)
        .transition()
        .duration(ANIMATION_DURATION)
        .ease(d3.easeLinear)
        .attr("cx", (d) => xMatched(d.value2))
        .attr("cy", (d) => yMatched(d.group * 2 + 1));
      setMatched(true)
    } else if (matched) {
      svg.select("svg").remove();
      setMatched(false)
    }
  }
  return (
    <div
      style={{
        display: "grid",
      }}>
      <div
        style={{
          margin: "0 auto",
          width: "100%"
        }}
        ref={d3Container}
      />
      <button
        className={'ripple-button'}
        style={{
          margin: "0 auto"
        }}
        onClick={handleMatch}>
        {!matched ?
          "Match Observations"
          :
          "Reset"}
      </button>
    </div>
  );
}
