import React, { useRef, useEffect, useState } from 'react'
import * as d3 from 'd3'
import { matchingData } from "../data/estimating-cate.js"
import { subClassificationTE } from "../data/estimating-cate.js"
var NUM_SAMPLES = 8;
var horizBarriers = [...Array(7).keys()].map(i => 10 * (i + 1));

var margin = { top: 20, right: 30, bottom: 50, left: 50 },
        width = 375 - margin.left - margin.right,
        height = 420 - margin.top - margin.bottom;
       var x = d3.scaleLinear()
        .domain([0, 1])
        .range([0, width]);
      var y = d3.scaleLinear()
        .range([0, height])
        .domain([80,0])
  var legendX = 30
  var legendY = 40

export const SubClassification = () => {
  const d3Container = useRef(null);
  const [matched, setMatched] = useState(false)

  useEffect(
    () => {
      const produceScatterplot = (svg) => {
       // set the dimensions and margins of the graph
       var margin = { top: 20, right: 30, bottom: 50, left: 50 },
       width = 375 - margin.left - margin.right,
       height = 420 - margin.top - margin.bottom;
     // Parse the Data
     const unmatchedDataExposed = [...Array(NUM_SAMPLES).keys()].map(index => {
       var d = {}
       d.value = 1
       d.group = (index + (NUM_SAMPLES * 2.2))*3 ;
       return d
     })
     
     const unmatchedDataUnexposed = [...Array(NUM_SAMPLES).keys()].map(index => {
       var d = {}
       d.value = 0;
       d.group = index*3 + 20;
       return d
     })
     
       // X axis
       svg.append("g")
         .attr("transform", "translate(0," + height + ")")
         .call(d3.axisBottom(x))

       // text label for the x axis
       svg.append("text")
         .attr("transform",
           "translate(" + (width / 2) + " ," +
           (height + margin.top + 20) + ")")
         .style("text-anchor", "middle")
         .style("font-weight","bold")
         .style("fill","#EF3E36")
         .text("Purchases Flight");

      

             // Lines
             svg.selectAll("myline")
             .data(horizBarriers)
             .enter()
             .append("line")
             .attr("id", "horizLine")
             .attr("x1", () => x(0))
             .attr("x2", () => x(0))
             .attr("y1", (d) => (y(d)+0.5))
             .attr("y2", (d) => (y(d)+0.5))
             .attr("stroke", "black")
             .attr("stroke-width", "1px") 
             .style("stroke-dasharray", ("3, 3"))
             

           // Bars
           svg.selectAll("myrect")
           .data(subClassificationTE)
           .enter()
           .append("rect")
           .attr("id", "TErect")
           .attr("x", () => x(0))
           .attr("y", (d,i) => y(i*10+7))
           .attr("width", () => x(0))
           .attr("height", () => y(0)-y(4))
           .attr("fill", "#EF3E36")
           .attr("stroke-width", "1px") 

            // Y axis

       svg.append("g")
       .call(d3.axisLeft(y))
       // text label for the y axis
       svg.append("text")
         .attr("transform", "rotate(-90)")
         .attr("y", 0 - margin.left)
         .attr("x", 0 - (height / 2))
         .attr("dy", "1em")
         .style("text-anchor", "middle")
         .text("Age")
         .style("font-weight","bold")
         .style("fill","#7A28CB");
       var legendX = 30
       var legendY = 40
       //Legend
       svg.append("rect")
         .attr("x", legendX - 10)
         .attr("y", legendY - 15)
         .attr("width", 140)
         .attr("height", 45)
         .attr("fill", "white")
         .attr("stroke", "black")
         .attr("stroke-width", "1px")

       svg.append("circle").attr("cx", legendX).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
       svg.append("circle").attr("cx", legendX).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
       svg.append("text").attr("x", legendX + 10).attr("y", legendY).text("Exposed To Ad").style("font-size", "10px").attr("alignment-baseline", "middle")
       svg.append("text").attr("x", legendX + 10).attr("y", legendY + 15).text("Unexposed To Ad").style("font-size", "10px").attr("alignment-baseline", "middle")

       // Circles of variable 1
       svg.selectAll("mycircle")
         .data(matchingData)
         .enter()
         .append("circle")
         .attr("id", "mycircle1")
         .attr("cx", function (d) { return x(d.value1); })
         .attr("cy", function (d) { return y(d.group * 2 + NUM_SAMPLES); })
         .attr("r", "4")
         .style("fill", "#7A28CB")

       // Circles of variable 2
       svg.selectAll("mycircle")
         .data(matchingData)
         .enter()
         .append("circle")
         .attr("id", "mycircle2")
         .attr("cx", function (d) { return x(d.value2); })
         .attr("cy", function (d) { return y(d.group * 2 + 1 + NUM_SAMPLES); })
         .attr("r", "4")
         .style("fill", "#caa6ed")

      
     

       // Circles of variable 1 unmatched
       svg.selectAll("mycircle")
         .data(unmatchedDataExposed)
         .enter()
         .append("circle")
         .attr("id", "mycircle1")
         .attr("cx", function (d) { return x(d.value); })
         .attr("cy", function (d) { return y(d.group); })
         .attr("r", "4")
         .style("fill", "#7A28CB")

       // Circles of variable 2 unmatched
       svg.selectAll("mycircle")
         .data(unmatchedDataUnexposed)
         .enter()
         .append("circle")
         .attr("id", "mycircle2")
         .attr("cx", function (d) { return x(d.value); })
         .attr("cy", function (d) { return y(d.group); })
         .attr("r", "4")
         .style("fill", "#caa6ed")
    }
    

      if (d3Container.current && !matched) {
        var svg = d3.select(d3Container.current)
          .append("svg")
          .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
          .append("g")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");
          produceScatterplot(svg);

      }

    },
    [matched])

  const handleMatch = () => {
    // Circles of variable 2
    // append the svg object to the body of the page
    var svg = d3.select(d3Container.current)

    

    if (!matched) {

      svg.selectAll("#horizLine")
      .data(subClassificationTE)
      .transition()
      .duration(3000)
      .ease(d3.easeLinear)
      .attr("x2", function (d) { return x(1); })

      svg.selectAll("#TErect")
      .data(subClassificationTE)
      .transition()
      .delay(3000)
      .duration(3000)
      .ease(d3.easeLinear)
      .attr("width",  function (d) { return x(d); })

       //Legend
       svg.append("rect")
       .attr("x", legendX - 10)
       .attr("y", legendY - 15)
       .attr("width", 140)
       .attr("height", 45)
       .attr("fill", "white")
       .attr("stroke", "black")
       .attr("stroke-width", "1px")
       
   svg.append("circle").attr("cx", legendX).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
   svg.append("circle").attr("cx", legendX).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
  


      setMatched(true)
    } else if (matched) {
      svg.select("svg").remove();
      setMatched(false)
    }
  }
  return (
    <div
      style={{
        display: "grid",
      }}>
      <div
        style={{
          margin: "0 auto",
          width: "100%"
        }}
        ref={d3Container}
      />
      <button
        className={'ripple-button'}
        style={{
          margin: "0 auto"
        }}
        onClick={handleMatch}>
        {!matched ?
          "Sub-Classify"
          :
          "Reset"}
      </button>
    </div>
  );
}
