import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
var NUM_SAMPLES = 8;
var MAX_AGE = 80;
/* Component */
export const LogisticRegression = () => {
  const d3Container = useRef(null);
  // set the dimensions and margins of the graph

  useEffect(
    () => {
      var margin = { top: 20, right: 30, bottom: 50, left: 50 },
        width = 375 - margin.left - margin.right,
        height = 420 - margin.top - margin.bottom;
        var legendX = 30
        var legendY = 40
      // Parse the Data
      var data = [...Array(NUM_SAMPLES).keys()];

      function sigmoid(t) {
        t = t * 30 - 15
        return 1 / (1 + Math.pow(Math.E, -t));
      }


      var dataExposed = data.map((index) => {
        var d = {}
        d.x = Math.random() * (MAX_AGE / 2) + MAX_AGE / 2 - Math.random() * (MAX_AGE / 8);
        d.y = 1
        return d
      })

      var dataUnexposed = data.map((index) => {
        var d = {}
        d.x = Math.random() * (MAX_AGE / 2) + Math.random() * (MAX_AGE / 8);
        d.y = 0
        return d
      })


      var x = d3.scaleLinear()
        .domain([0, MAX_AGE])
        .range([0, width]);
      var y = d3.scaleLinear()
        .range([0, height])
        .domain([1, 0]);
      var lineData = [...Array(100).keys()].map((index) => {
        return {
          x: (index / 100) * MAX_AGE,
          y: sigmoid(index / 100)
        };
      })
      if (d3Container.current) {
        // append the svg object to the body of the page
        var svg = d3.select(d3Container.current)
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");
        // X axis
        svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x))

        // text label for the x axis
        svg.append("text")
          .attr("transform",
            "translate(" + (width / 2) + " ," +
            (height + margin.top + 20) + ")")
          .style("text-anchor", "middle")
          .text("Age");

        // Y axis

        svg.append("g")
          .call(d3.axisLeft(y))

        // text label for the y axis
        svg.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 0 - margin.left)
          .attr("x", 0 - (height / 2))
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text("Probability Of Exposure");

        // Circles of variable 1 unmatched
        svg.selectAll("mycircle")
          .data(dataExposed)
          .enter()
          .append("circle")
          .attr("id", "mycircle1")
          .attr("cx", function (d) { return x(d.x); })
          .attr("cy", function (d) { return y(d.y); })
          .attr("r", "4")
          .style("fill", "#7A28CB")
          
          //Legend
          svg.append("rect")
          .attr("x", legendX - 10)
          .attr("y", legendY- 15)
          .attr("width", 140)
          .attr("height", 45)
          .attr("fill", "white")
          .attr("stroke", "black")
          .attr("stroke-width", "1px")

      svg.append("circle").attr("cx", legendX).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
      svg.append("circle").attr("cx", legendX).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
      svg.append("text").attr("x", legendX + 10).attr("y", legendY).text("Exposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")
      svg.append("text").attr("x", legendX + 10).attr("y", legendY + 15).text("Unexposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")
      
        // Circles of variable 2 unmatched
        svg.selectAll("mycircle")
          .data(dataUnexposed)
          .enter()
          .append("circle")
          .attr("id", "mycircle2")
          .attr("cx", function (d) { return x(d.x); })
          .attr("cy", function (d) { return y(d.y); })
          .attr("r", "4")
          .style("fill", "#caa6ed")
        //Line Plot
        let line = d3.line()
          .x(d => x(d.x))
          .y(d => y(d.y))

        svg.append('path')
          .datum(lineData)
          .attr("clip-path", "url(#chart-area)")
          .attr('fill', 'none')
          .attr('stroke', '#7A28CB')
          .style("stroke-dasharray", ("10, 3"))
          .attr('stroke-width', 2)
          .attr('d', line)

      }
    },
    [])

  return (
    <div
    style={{
      display: "grid",
    }}>
      <div
        style={{
          margin: "0 auto",
        }}
        className="my_dataviz"
        ref={d3Container}
      />
    </div>
  );
}
