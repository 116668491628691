import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import {balancedBins} from "../data/balance-histograms.js"

/* Component */
export const DoubleHistogramBalanced = () => {
    const d3Container = useRef(null);

    useEffect(
        () => {
            // set the dimensions and margins o f the graph
            var margin = { top: 10, right: 30, bottom: 30, left: 40 },
                width = 460 - margin.left - margin.right,
                height = 400 - margin.top - margin.bottom;

            // append the svg object to the body of the page
            var svg = d3.select(d3Container.current)
                .append("svg")
                .attr("preserveAspectRatio", "xMinYMin meet")
                .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
                .append("g")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")");


            // X axis: scale and draw:
            var x = d3.scaleLinear()
                .domain([0, 1])     // can use this instead of 1000 to have the max of data: d3.max(data, function(d) { return +d.price })
                .range([0, width]);
            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x));

             // And apply twice this function to data to get the bins.
             var {bins1,bins2} = balancedBins;
        
             // Y axis: scale and draw:
             var y = d3.scaleLinear()
                 .range([height, 0]);
             y.domain([0, d3.max(bins1, function (d) { return d.size; })]);   // d3.hist has to be called before the Y axis obviously   // d3.hist has to be called before the Y axis obviously
             svg.append("g")
                 .call(d3.axisLeft(y));
 
             // append the bars for series 1
             svg.selectAll("rect")
                 .data(bins1)
                 .enter()
                 .append("rect")
                 .attr("x", 1)
                 .attr("transform", function (d) { return "translate(" + x(d.x0) + "," + y(d.size) + ")"; })
                 .attr("width", function (d) { return x(d.x1) - x(d.x0) - 1; })
                 .attr("height", function (d) { return height - y(d.size); })
                 .style("fill", "#caa6ed")
                 .style("opacity", 0.6)
 
             // append the bars for series 2
             svg.selectAll("rect2")
                 .data(bins2)
                 .enter()
                 .append("rect")
                 .attr("x", 1)
                 .attr("transform", function (d) { return "translate(" + x(d.x0) + "," + y(d.size) + ")"; })
                 .attr("width", function (d) { return x(d.x1) - x(d.x0) - 1; })
                 .attr("height", function (d) { return height - y(d.size); })
                 .style("fill", "#7A28CB")
                 .style("opacity", 0.6)

                var legendX = 30
                var legendY = 40//Legend
                svg.append("rect")
                .attr("x", legendX - 10)
                .attr("y", legendY- 15)
                .attr("width", 140)
                .attr("height", 45)
                .attr("fill", "white")
                .attr("stroke", "black")
                .attr("stroke-width", "1px")
        
            svg.append("circle").attr("cx", legendX).attr("cy", legendY).attr("r", 4).style("fill", "#7A28CB")
            svg.append("circle").attr("cx", legendX).attr("cy", legendY + 15).attr("r", 4).style("fill", "#caa6ed")
            svg.append("text").attr("x", legendX + 10).attr("y", legendY+1).text("Exposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")
            svg.append("text").attr("x", legendX + 10).attr("y", legendY + 16).text("Unexposed To Treatment").style("font-size", "10px").attr("alignment-baseline", "middle")
            
        },
        [])

    return (
        <div
    style={{
      display: "grid",
    }}>
      <div
        style={{
          margin: "0 auto",
          width:"100%"
        }}
        ref={d3Container}
      />
        </div>
    );
}
